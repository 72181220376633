import { DateUtils } from "../../../classes/date.utils";
export function stringToDateOrNull(str) {
    const date = DateUtils.stringToDate(str);
    return isNaN(date === null || date === void 0 ? void 0 : date.getTime()) ? null : date;
}
export function timeToDate(value) {
    return new Date(`0000-01-01T${value}`);
}
export function formatToTimeString(value) {
    if (value == null) {
        return null;
    }
    const date = timeToDate(value);
    if (isNaN(date.getTime())) {
        return null;
    }
    else {
        const splittedTime = value.split(":").map((val) => parseFloat(val));
        if ((splittedTime[0] != null && splittedTime[0] !== date.getHours()) ||
            (splittedTime[1] != null && splittedTime[1] !== date.getMinutes())) {
            return null;
        }
        return value;
    }
}
export function extractRange(str, mode) {
    if (mode === "date") {
        return extractDateRange(str);
    }
    else {
        return extractTimeRange(str);
    }
}
export function extractTimeRange(str) {
    const splitted = str === null || str === void 0 ? void 0 : str.split(",");
    const from = formatToTimeString(splitted === null || splitted === void 0 ? void 0 : splitted[0]);
    const to = formatToTimeString(splitted === null || splitted === void 0 ? void 0 : splitted[1]);
    return { from: from, to: to };
}
export function extractDateRange(str) {
    const range = str === null || str === void 0 ? void 0 : str.split(",");
    const fromDate = stringToDateOrNull(range === null || range === void 0 ? void 0 : range[0]);
    const toDate = stringToDateOrNull(range === null || range === void 0 ? void 0 : range[1]);
    return {
        from: fromDate,
        to: toDate,
    };
}
export function convertToRangeString(from, to, mode) {
    if (mode === "date") {
        return convertToRangeDateString(from, to);
    }
    else {
        return convertToRangeTimeString(from, to);
    }
}
export function convertToRangeDateString(from, to) {
    var _a, _b;
    return `${(_a = DateUtils.dateToString(from)) !== null && _a !== void 0 ? _a : ""},${(_b = DateUtils.dateToString(to)) !== null && _b !== void 0 ? _b : ""}`;
}
export function convertToRangeTimeString(from, to) {
    return `${from !== null && from !== void 0 ? from : ""},${to !== null && to !== void 0 ? to : ""}`;
}
